<template>

  <vx-card style="padding-left: 10px;overflow:hidden !important">
 
      <vs-button ref="loadableButton" 
      id="button-with-loading" 
      class="mr-3 mb-2" 
      @click="restore(subscriptionId)"
      type="filled" 
      color="success"
      vslor="primary">Restore
      </vs-button>

    <vs-button 
    color="warning"
    class="mr-3 mb-2"
    @click="goBack"
    >Back</vs-button>
    <pre
     v-if="subscriptionData"
    >
      {{subscriptionData}}
    </pre>
  </vx-card>
</template>
<script>
  import vSelect from "vue-select";
  import { mapActions } from "vuex";
  import VueJsonPretty from "vue-json-pretty";
  import moment from "moment";
  export default {
    components: {
      "v-select": vSelect,
      VueJsonPretty,
    },
    data() {
      return {
        subscriptionId: null,
        subscriptionData: null,
      };
    },
    methods: {
      ...mapActions("admin", ["fetchIosJsonSubscriptionDetails", "restoreSubscription"]),
      getSubscriptionDetils(id) {
        let self = this;
        let data = {
          subscriptionId: id,
        };
        this.fetchIosJsonSubscriptionDetails(data).then((res) => {
          this.subscriptionData = res.data.data;
        });
      },
      goBack() {
        this.$router.go(-1)
      },
      restore(id) {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: "#button-with-loading",
          scale: 0.45
        })
        let seft = this;
        let data = {
          subscriptionId: id,
        };
        this.restoreSubscription(data).then((res) => {
          this.subscriptionData = res.data.data;
          this.$vs.notify({
            title: "Subscription restored",
            text: 'Subscription restored successfully',
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$vs.loading.close("#button-with-loading > .con-vs-loading")
        });

      }
    },
    created() {
      let subscriptionId = this.$route.params.id;
      this.subscriptionId = subscriptionId
      this.getSubscriptionDetils(subscriptionId);
    },
  };
</script>
